<style lang="scss">
#employee-edit-wizard {
  .navigation-employee {
    height: 42px;
    padding: 10px;
    background: #1e1e2d;
    border-bottom: 1px solid #fff;
  }
  .nav {
    font-size: 14px;
    background: #1e1e2d;
    padding: 10px;
    font-weight: 600;
    .nav-item {
      .nav-link {
        color: white !important;
      }
      .active {
        border-bottom: 2px solid;
      }
    }
  }
  .card {
    border-bottom-left-radius: 0.42rem !important;
    border-bottom-right-radius: 0.42rem !important;
    border-radius: 0;
  }
}
</style>
<template>
  <div id="employee-edit-wizard">
    <!-- <div class="rounded-top navigation-employee">

    </div> -->

    <ul class="nav justify-content-center">
      <!-- 

        <li class="nav-item">
        <router-link
          :to="{ name:'employee.profile'}"
          class="nav-link"
          :class="route == 'employee.profile' ? 'active' : ''"
          aria-current="page"
          >Profile</router-link
        >
      </li> -->

      <li class="nav-item">
        <router-link
          :to="{ name: 'employee.edit.personal' }"
          class="nav-link"
          :class="route == 'employee.edit.personal' ? 'active' : ''"
          aria-current="page"
          >Personal</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'employee.edit.document' }"
          :class="route == 'employee.edit.document' ? 'active' : ''"
          class="nav-link"
          >Documents</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'employee.edit.contract' }"
          :class="route == 'employee.edit.contract' ? 'active' : ''"
          class="nav-link"
          >Contract</router-link
        >
      </li>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import { FETCH_EMPLOYEE_CONTRACTS } from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      route: "employee.edit.personal"
    };
  },
  watch: {
    $route(to) {
      this.route = to.name;
    }
  },
  mounted() {
    this.route = this.$route.name;
    const id = this.$route.params.id;
    this.fetchEmployeeContracts(id);
  },
  methods: {
    fetchEmployeeContracts(id) {
      this.loader = true;
      this.$store
        .dispatch(FETCH_EMPLOYEE_CONTRACTS, id)
        .then(() => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
</script>
